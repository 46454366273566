<template>
  <v-data-table :headers="headers" :items="listRequirementCategory" dense @click:row="onClickRow">
    <template v-slot:top>
      <v-toolbar flat>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="success"
              v-on="on"
              dark
              rounded
              :to="{ name: 'Create Requirement Category' }"
            >
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <span>Create</span>
        </v-tooltip>
      </v-toolbar>
    </template>
    <template v-slot:item.no="{ item }">
      {{ listRequirementCategory.map(x => x).indexOf(item) + 1 }}
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "requirement-category",
  data: () => ({
    headers: [
      {
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Name",
        value: "name",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Account Number",
        value: "accountNumber",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
  }),

  computed: {
    ...mapState("requirementCategory", ["listRequirementCategory"]),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("requirementCategory/getAll")
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    onClickRow(item) {
      this.$router.push({ name: "Detail Requirement Category", params: { id: item.id } });
    },
  },
};
</script>

<style></style>
